.show {
  display: block !important;
}
.hidden {
  display: none !important;
}
.invisible {
  visibility: hidden;
}
/* CALENDAR 
=====================*/
.nav-calendar.nav-calendar-teacher {
  margin-top: 0;
}
.nav-calendar.nav-calendar-teacher .calendar-options {
  float: right;
}
.nav-calendar.nav-calendar-teacher .calendar-options li a {
  border: 1px solid #88b916;
  padding: 5px 8px;
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus {
  background-color: #88b916;
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover .icon-calendar-view,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus .icon-calendar-view {
  background-image: url("../img/icon-calendar-view-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover .icon-calendar-outlook,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus .icon-calendar-outlook {
  background-image: url("../img/icon-calendar-outlook-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover .icon-calendar-google,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus .icon-calendar-google {
  background-image: url("../img/icon-calendar-google-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover .icon-calendar-iphone,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus .icon-calendar-iphone {
  background-image: url("../img/icon-calendar-iphone-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover .icon-calendar-ask-class,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus .icon-calendar-ask-class {
  background-image: url("../img/icon-calendar-ask-class-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover .icon-calendar-change-class,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus .icon-calendar-change-class {
  background-image: url("../img/icon-calendar-change-class-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover .icon-calendar-collapse,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus .icon-calendar-collapse {
  background-image: url("../img/icon-calendar-collapse-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li a:hover .icon-calendar-add,
.nav-calendar.nav-calendar-teacher .calendar-options li a:focus .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.important a {
  border: 1px solid #ff6e4c;
}
.nav-calendar.nav-calendar-teacher .calendar-options li.important a .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-important.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.important a:hover,
.nav-calendar.nav-calendar-teacher .calendar-options li.important a:focus {
  background-color: #ff6e4c;
  border: 1px solid #ff6e4c;
}
.nav-calendar.nav-calendar-teacher .calendar-options li.important a:hover .icon-calendar-add,
.nav-calendar.nav-calendar-teacher .calendar-options li.important a:focus .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.filled a {
  color: white;
  background-color: #88b916;
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a {
  background-color: #88b916;
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a .icon-calendar-view {
  background-image: url("../img/icon-calendar-view-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a .icon-calendar-outlook {
  background-image: url("../img/icon-calendar-outlook-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a .icon-calendar-google {
  background-image: url("../img/icon-calendar-google-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a .icon-calendar-iphone {
  background-image: url("../img/icon-calendar-iphone-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a .icon-calendar-ask-class {
  background-image: url("../img/icon-calendar-ask-class-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a .icon-calendar-change-class {
  background-image: url("../img/icon-calendar-change-class-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a .icon-calendar-collapse {
  background-image: url("../img/icon-calendar-collapse-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active a .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-hover.png");
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active.important a {
  background-color: #ff6e4c !important;
  border: 1px solid #ff6e4c !important;
}
.nav-calendar.nav-calendar-teacher .calendar-options li.active.important a .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-hover.png") !important;
}
.nav-calendar.nav-calendar-teacher .calendar-options a.pending-lessons {
  color: #ff6e4c;
  font-size: 20px;
  padding-right: 30px;
  border: 0;
}
.nav-calendar.nav-calendar-teacher .calendar-options a.pending-lessons.active,
.nav-calendar.nav-calendar-teacher .calendar-options a.pending-lessons:hover,
.nav-calendar.nav-calendar-teacher .calendar-options a.pending-lessons:focus {
  background-color: white;
}
.page-header .user-data {
  display: block;
  font-size: 20px;
  line-height: 1.1;
  margin: 20px 0 10px;
}
.page-header .nav-page {
  margin-top: 0;
}
.page-header .nav-page .calendar-options {
  float: right;
}
.page-header .nav-page .calendar-options li a {
  border: 1px solid #88b916;
  padding: 5px 8px;
}
.page-header .nav-page .calendar-options li a:hover,
.page-header .nav-page .calendar-options li a:focus {
  background-color: #88b916;
}
.page-header .nav-page .calendar-options li a:hover .icon-calendar-view,
.page-header .nav-page .calendar-options li a:focus .icon-calendar-view {
  background-image: url("../img/icon-calendar-view-hover.png");
}
.page-header .nav-page .calendar-options li a:hover .icon-calendar-outlook,
.page-header .nav-page .calendar-options li a:focus .icon-calendar-outlook {
  background-image: url("../img/icon-calendar-outlook-hover.png");
}
.page-header .nav-page .calendar-options li a:hover .icon-calendar-google,
.page-header .nav-page .calendar-options li a:focus .icon-calendar-google {
  background-image: url("../img/icon-calendar-google-hover.png");
}
.page-header .nav-page .calendar-options li a:hover .icon-calendar-iphone,
.page-header .nav-page .calendar-options li a:focus .icon-calendar-iphone {
  background-image: url("../img/icon-calendar-iphone-hover.png");
}
.page-header .nav-page .calendar-options li a:hover .icon-calendar-ask-class,
.page-header .nav-page .calendar-options li a:focus .icon-calendar-ask-class {
  background-image: url("../img/icon-calendar-ask-class-hover.png");
}
.page-header .nav-page .calendar-options li a:hover .icon-calendar-change-class,
.page-header .nav-page .calendar-options li a:focus .icon-calendar-change-class {
  background-image: url("../img/icon-calendar-change-class-hover.png");
}
.page-header .nav-page .calendar-options li a:hover .icon-calendar-collapse,
.page-header .nav-page .calendar-options li a:focus .icon-calendar-collapse {
  background-image: url("../img/icon-calendar-collapse-hover.png");
}
.page-header .nav-page .calendar-options li a:hover .icon-calendar-add,
.page-header .nav-page .calendar-options li a:focus .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-hover.png");
}
.page-header .nav-page .calendar-options li.important a {
  border: 1px solid #ff6e4c;
}
.page-header .nav-page .calendar-options li.important a .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-important.png");
}
.page-header .nav-page .calendar-options li.important a:hover,
.page-header .nav-page .calendar-options li.important a:focus {
  background-color: #ff6e4c;
  border: 1px solid #ff6e4c;
}
.page-header .nav-page .calendar-options li.important a:hover .icon-calendar-add,
.page-header .nav-page .calendar-options li.important a:focus .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-hover.png");
}
.page-header .nav-page .calendar-options li.active a {
  background-color: #88b916;
}
.page-header .nav-page .calendar-options li.active a .icon-calendar-view {
  background-image: url("../img/icon-calendar-view-hover.png");
}
.page-header .nav-page .calendar-options li.active a .icon-calendar-outlook {
  background-image: url("../img/icon-calendar-outlook-hover.png");
}
.page-header .nav-page .calendar-options li.active a .icon-calendar-google {
  background-image: url("../img/icon-calendar-google-hover.png");
}
.page-header .nav-page .calendar-options li.active a .icon-calendar-iphone {
  background-image: url("../img/icon-calendar-iphone-hover.png");
}
.page-header .nav-page .calendar-options li.active a .icon-calendar-ask-class {
  background-image: url("../img/icon-calendar-ask-class-hover.png");
}
.page-header .nav-page .calendar-options li.active a .icon-calendar-change-class {
  background-image: url("../img/icon-calendar-change-class-hover.png");
}
.page-header .nav-page .calendar-options li.active a .icon-calendar-collapse {
  background-image: url("../img/icon-calendar-collapse-hover.png");
}
.page-header .nav-page .calendar-options li.active a .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-hover.png");
}
.page-header .nav-page .calendar-options li.active.important a {
  background-color: #ff6e4c !important;
  border: 1px solid #ff6e4c !important;
}
.page-header .nav-page .calendar-options li.active.important a .icon-calendar-add {
  background-image: url("../img/icon-calendar-add-hover.png") !important;
}
.page-header .nav-page .calendar-options a.pending-lessons {
  color: #ff6e4c;
  font-size: 20px;
  padding-right: 30px;
  border: 0;
}
.page-header .nav-page .calendar-options a.pending-lessons.active,
.page-header .nav-page .calendar-options a.pending-lessons:hover,
.page-header .nav-page .calendar-options a.pending-lessons:focus {
  background-color: white;
}
.calendar {
  -webkit-box-shadow: 0 2px 3px 1px rgba(168, 169, 170, 0.44);
  -moz-box-shadow: 0 2px 3px 1px rgba(168, 169, 170, 0.44);
  box-shadow: 0 2px 3px 1px rgba(168, 169, 170, 0.44);
  position: relative;
}
.calendar:before,
.calendar:after {
  content: " ";
  display: table;
}
.calendar:after {
  clear: both;
}
.calendar .calendar-header {
  display: block;
  text-align: center;
  color: #fff;
  position: relative;
  border-top: 1px solid #ebebeb;
  padding: 5px 15px;
}
.calendar .calendar-header h3 {
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
  color: #56565b;
  font-size: 22px;
}
.calendar .calendar-header .btn {
  margin-top: -10px;
}
.calendar .calendar-body {
  position: relative;
}
.calendar .calendar-body .cal-view-month,
.calendar .calendar-body .cal-view-week {
  position: relative;
}
.calendar .calendar-body .cal-view-month table,
.calendar .calendar-body .cal-view-week table {
  width: 100%;
  background: #f9f7f4 none repeat scroll 0 0;
}
.calendar .calendar-body .cal-view-month table thead tr th,
.calendar .calendar-body .cal-view-week table thead tr th {
  background: #eee none repeat scroll 0 0;
  border-right: 1px solid #55555b;
  padding: 5px 0;
  text-align: center;
}
.calendar .calendar-body .cal-view-month table thead tr th:last-child,
.calendar .calendar-body .cal-view-week table thead tr th:last-child {
  border: none;
}
.calendar .calendar-body .cal-view-month table tr td.hour:first-child,
.calendar .calendar-body .cal-view-week table tr td.hour:first-child {
  text-align: right;
  padding-right: 20px;
  font-size: 15px !important;
  width: 10%;
}
.calendar .calendar-body .cal-view-month table tbody tr td.not-available,
.calendar .calendar-body .cal-view-week table tbody tr td.not-available {
  background-color: #e2271545 !important;
}
.calendar .calendar-body .cal-view-month table tbody tr td.empty,
.calendar .calendar-body .cal-view-week table tbody tr td.empty {
  background-color: #eaeaea;
}
.calendar .calendar-body .cal-view-month table tbody tr td.empty .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.empty .cal-class {
  display: none;
}
.calendar .calendar-body .cal-view-month table tbody tr td.hour,
.calendar .calendar-body .cal-view-week table tbody tr td.hour {
  max-height: 90px !important;
  height: 15px;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day,
.calendar .calendar-body .cal-view-week table tbody tr td.day,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day,
.calendar .calendar-body .cal-view-month table tbody tr td.hour,
.calendar .calendar-body .cal-view-week table tbody tr td.hour,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour {
  border: 1px solid #eee;
  height: 121px;
  padding-top: 25px;
  padding-bottom: 5px;
  position: relative;
  width: 14%;
  vertical-align: top;
  font-size: 12px;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class {
  border-left: 4px solid #ccc;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day.active,
.calendar .calendar-body .cal-view-week table tbody tr td.day.active,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day.active,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day.active,
.calendar .calendar-body .cal-view-month table tbody tr td.hour.active,
.calendar .calendar-body .cal-view-week table tbody tr td.hour.active,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour.active,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour.active {
  outline: 1px solid #ff6e4c;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day.today .label-class,
.calendar .calendar-body .cal-view-week table tbody tr td.day.today .label-class,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day.today .label-class,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day.today .label-class,
.calendar .calendar-body .cal-view-month table tbody tr td.hour.today .label-class,
.calendar .calendar-body .cal-view-week table tbody tr td.hour.today .label-class,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour.today .label-class,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour.today .label-class {
  background-color: #88b916;
  position: absolute;
  top: 0;
  left: 0;
  width: 55%;
  font-size: 12px;
  padding: 3px;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day.today .cal-class.success,
.calendar .calendar-body .cal-view-week table tbody tr td.day.today .cal-class.success,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day.today .cal-class.success,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day.today .cal-class.success,
.calendar .calendar-body .cal-view-month table tbody tr td.hour.today .cal-class.success,
.calendar .calendar-body .cal-view-week table tbody tr td.hour.today .cal-class.success,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour.today .cal-class.success,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour.today .cal-class.success {
  border-left: 4px solid #88b916;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day.next .label-class,
.calendar .calendar-body .cal-view-week table tbody tr td.day.next .label-class,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day.next .label-class,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day.next .label-class,
.calendar .calendar-body .cal-view-month table tbody tr td.hour.next .label-class,
.calendar .calendar-body .cal-view-week table tbody tr td.hour.next .label-class,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour.next .label-class,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour.next .label-class {
  background-color: #ff6e4c;
  position: absolute;
  top: 0;
  left: 0;
  width: 55%;
  font-size: 12px;
  padding: 3px;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day.next .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.day.next .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day.next .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day.next .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.hour.next .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.hour.next .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour.next .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour.next .cal-class {
  border-left: 4px solid #ff6e4c;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day.future .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.day.future .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day.future .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day.future .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.hour.future .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.hour.future .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour.future .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour.future .cal-class {
  border-left: 4px solid #ccc;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day.holiday,
.calendar .calendar-body .cal-view-week table tbody tr td.day.holiday,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day.holiday,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day.holiday,
.calendar .calendar-body .cal-view-month table tbody tr td.hour.holiday,
.calendar .calendar-body .cal-view-week table tbody tr td.hour.holiday,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour.holiday,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour.holiday {
  background-color: #eee;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day.holiday .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.day.holiday .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day.holiday .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day.holiday .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.hour.holiday .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.hour.holiday .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour.holiday .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour.holiday .cal-class {
  border-left: 4px solid #ebb700;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class {
  margin: 0 3px 3px;
  position: relative;
  background-color: #fff;
  min-height: 34px;
  -webkit-box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.44);
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.44);
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class:before,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class:before,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class:before,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class:before,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class:before,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class:before,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class:before,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class:before,
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class:after,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class:after,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class:after,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class:after,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class:after,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class:after,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class:after,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class:after {
  content: " ";
  display: table;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class:after,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class:after,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class:after,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class:after,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class:after,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class:after,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class:after,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class:after {
  clear: both;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class.success,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class.success,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class.success,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class.success,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class.success,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class.success,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class.success,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class.success {
  border-left: 4px solid #88b916;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class.danger,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class.danger,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class.danger,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class.danger,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class.danger,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class.danger,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class.danger,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class.danger {
  border-left: 4px solid #ff6e4c !important;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class.info,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class.info,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class.info,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class.info,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class.info,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class.info,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class.info,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class.info {
  border-left: 4px solid #307bbb !important;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .content-info,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .content-info,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .content-info,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .content-info,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .content-info,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .content-info,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .content-info,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .content-info {
  overflow: hidden;
  width: 98%;
  color: white;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .content-image,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .content-image,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .content-image,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .content-image,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .content-image,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .content-image,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .content-image,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .content-image {
  width: 30%;
  float: right;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .content-image i,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .content-image i,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .content-image i,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .content-image i,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .content-image i,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .content-image i,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .content-image i,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .content-image i {
  float: right;
  margin: 3px 3px 3px 0px;
  background-size: 100% 100%;
  width: 30px;
  height: 30px;
  min-height: 28px;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .class-title,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .class-title,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .class-title,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .class-title,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .class-title,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .class-title,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .class-title,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .class-title,
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .class-hour,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .class-hour,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .class-hour,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .class-hour,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .class-hour,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .class-hour,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .class-hour,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .class-hour {
  padding-left: 3px;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .class-title p,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .class-title p,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .class-title p,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .class-title p,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .class-title p,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .class-title p,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .class-title p,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .class-title p,
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .class-hour p,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .class-hour p,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .class-hour p,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .class-hour p,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .class-hour p,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .class-hour p,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .class-hour p,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .class-hour p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .class-title.has-icon,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .class-title.has-icon,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .class-title.has-icon,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .class-title.has-icon,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .class-title.has-icon,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .class-title.has-icon,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .class-title.has-icon,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .class-title.has-icon,
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .class-hour.has-icon,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .class-hour.has-icon,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .class-hour.has-icon,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .class-hour.has-icon,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .class-hour.has-icon,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .class-hour.has-icon,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .class-hour.has-icon,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .class-hour.has-icon {
  width: 75%;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .class-title p,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .class-title p,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .class-title p,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .class-title p,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .class-title p,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .class-title p,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .class-title p,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .class-title p {
  font-size: 14px;
  margin-bottom: 0;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class .class-hour p,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class .class-hour p,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class .class-hour p,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class .class-hour p,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class .class-hour p,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class .class-hour p,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class .class-hour p,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class .class-hour p {
  padding-bottom: 0;
  margin-bottom: 0;
}
.calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class i,
.calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class i,
.calendar .calendar-body .cal-view-month table tbody tr td.all-day .cal-class i,
.calendar .calendar-body .cal-view-week table tbody tr td.all-day .cal-class i,
.calendar .calendar-body .cal-view-month table tbody tr td.hour .cal-class i,
.calendar .calendar-body .cal-view-week table tbody tr td.hour .cal-class i,
.calendar .calendar-body .cal-view-month table tbody tr td.half-hour .cal-class i,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour .cal-class i {
  float: right;
  margin: 3px;
}
.calendar .calendar-body .cal-view-month table tbody tr td .icon-plus,
.calendar .calendar-body .cal-view-week table tbody tr td .icon-plus {
  position: absolute;
  left: 7px;
  top: 7px;
}
.calendar .calendar-body .cal-view-month table tbody tr td .badge,
.calendar .calendar-body .cal-view-week table tbody tr td .badge {
  position: absolute;
  right: 5px;
  top: 5px;
}
.calendar .calendar-body .cal-view-month table tbody tr td .badge.badge-default,
.calendar .calendar-body .cal-view-week table tbody tr td .badge.badge-default {
  background-color: #ccc;
  color: #56565b;
}
.calendar .calendar-body .cal-view-month table tbody tr td .badge.badge-next,
.calendar .calendar-body .cal-view-week table tbody tr td .badge.badge-next {
  background-color: #ff6e4c;
}
.calendar .calendar-body .cal-view-month table tbody tr td .badge.badge-holiday,
.calendar .calendar-body .cal-view-week table tbody tr td .badge.badge-holiday {
  background-color: #ebb700;
}
.calendar .calendar-body .cal-view-month table tbody tr td .badge.badge-today,
.calendar .calendar-body .cal-view-week table tbody tr td .badge.badge-today {
  /*background-image: url("@{imgPath}/bg-task-correct.png");
              background-repeat: no-repeat;
              background-size: 100%;
              background-color: transparent;*/
  box-shadow: none;
  background-color: #88b916 !important;
  color: white !important;
}
.calendar .calendar-body .cal-view-month .classes-list,
.calendar .calendar-body .cal-view-week .classes-list {
  padding: 20px 0;
  background-color: #fff;
}
.calendar .calendar-body .cal-view-month .classes-list table td,
.calendar .calendar-body .cal-view-week .classes-list table td {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.calendar .calendar-body .cal-view-month .classes-list table td.success,
.calendar .calendar-body .cal-view-week .classes-list table td.success {
  border-left: 7px solid #88b916 !important;
}
.calendar .calendar-body .cal-view-month .classes-list table td.danger,
.calendar .calendar-body .cal-view-week .classes-list table td.danger {
  border-left: 7px solid #ff6e4c !important;
}
.calendar .calendar-body .cal-view-month .classes-list table td.info,
.calendar .calendar-body .cal-view-week .classes-list table td.info {
  border-left: 7px solid #307bbb !important;
}
.calendar .calendar-body .cal-view-month .classes-list table td.pending,
.calendar .calendar-body .cal-view-week .classes-list table td.pending {
  padding-left: 38px;
}
.calendar .calendar-body .cal-view-month .classes-list:before,
.calendar .calendar-body .cal-view-week .classes-list:before,
.calendar .calendar-body .cal-view-month .classes-list:after,
.calendar .calendar-body .cal-view-week .classes-list:after {
  content: " ";
  display: table;
}
.calendar .calendar-body .cal-view-month .classes-list:after,
.calendar .calendar-body .cal-view-week .classes-list:after {
  clear: both;
}
.calendar .calendar-body .cal-view-month .classes-list h4,
.calendar .calendar-body .cal-view-week .classes-list h4 {
  color: #56565b;
  padding-left: 10px;
  font-weight: 600;
}
.calendar .calendar-body .cal-view-month .classes-list ul,
.calendar .calendar-body .cal-view-week .classes-list ul {
  list-style: outside none none;
  padding: 0;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment {
  border-bottom: 1px solid #eee;
  display: flex;
  padding: 0 10px;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .content-image,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .content-image {
  width: 25%;
  max-width: 58px;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .content-title,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .content-title {
  width: 75%;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .scores,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .scores {
  font-size: 20px;
  font-weight: normal;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .img-mini,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .img-mini {
  display: inline-block;
  width: 45px;
  height: 45px;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment i.icon-check-correct,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment i.icon-check-correct,
.calendar .calendar-body .cal-view-month .classes-list .class-appointment i.icon-wrong,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment i.icon-wrong {
  display: inline-block;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment i.icon-pre-work,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment i.icon-pre-work,
.calendar .calendar-body .cal-view-month .classes-list .class-appointment i.icon-post-work,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment i.icon-post-work {
  width: 45px;
  height: 45px;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment.row,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment.row {
  margin: 0;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-hour,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-hour {
  text-align: right;
  border-right: 7px solid #ccc;
  padding-top: 12px;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-hour.success,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-hour.success {
  border-color: #88b916;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-hour.danger,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-hour.danger {
  border-color: #ff6e4c;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-hour.info,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-hour.info {
  border-color: 4px solid #307bbb;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class {
  padding: 10px;
  display: inline-flex;
  border-left: 1px solid #eee;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-title,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-title {
  padding-left: 10px;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-title.default img,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-title.default img {
  background: #cccccc;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-title.danger img,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-title.danger img {
  background: #ff6e4c;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-title.warning img,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-title.warning img {
  background: #ebb700;
}
.calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-title.success img,
.calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-title.success img {
  background: #88b916;
}
.calendar .calendar-body .cal-view-week table tbody tr td.day {
  height: 200px;
  background-color: white;
}
.calendar .calendar-body .cal-view-week table tbody tr td.all-day,
.calendar .calendar-body .cal-view-week table tbody tr td.hour,
.calendar .calendar-body .cal-view-week table tbody tr td.half-hour {
  height: 25px;
  padding-top: 5px;
  width: 18%;
}
.calendar .calendar-body .cal-view-week table tbody tr td.day.disabled {
  background-color: #f2f2f2;
  pointer-events: none;
}
.calendar .calendar-body .cal-view-week table tbody tr td.day.disabled .cal-class {
  display: none;
}
.calendar .calendar-body .cal-view-week table.timing th:first-child,
.calendar .calendar-body .cal-view-week table.timing td:first-child {
  width: 8%;
  text-align: center;
  vertical-align: middle;
}
.calendar .calendar-body .cal-view-week table.timing th .cal-class.success,
.calendar .calendar-body .cal-view-week table.timing td .cal-class.success {
  border-color: #88b916;
}
.calendar .calendar-body .cal-view-week table.timing th .cal-class.next,
.calendar .calendar-body .cal-view-week table.timing td .cal-class.next {
  border-color: #ff6e4c;
}
.calendar .calendar-body .calendar-lesson-list .item-description .container-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: #d6d6d6;
  border-radius: 50%;
  display: block;
}
.calendar .calendar-body .calendar-lesson-list .calendar-lesson-view > div {
  width: 70px;
  padding: 5px;
  display: inline-block;
  float: left;
}
.calendar .calendar-body .calendar-lesson-list .calendar-lesson-view > div.calendar-lesson-image a {
  display: inline-block;
  text-align: center;
}
.calendar .calendar-body .calendar-lesson-list .calendar-lesson-view > div > a {
  width: 100%;
}
.calendar .calendar-body .calendar-lesson-list .calendar-lesson-action a i {
  font-size: 25px;
}
.evaluations-env .calendar .calendar-header {
  background-color: #bebebe;
}
.evaluations-env .calendar .calendar-header h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
}
.evaluations-env .calendar .calendar-header .btn {
  margin-top: -5px !important;
}
.evaluations-env .calendar .calendar-header .btn.btn-prev {
  width: 30px;
  height: 30px;
  background-color: transparent;
  padding: 0;
}
.evaluations-env .calendar .calendar-header .btn.btn-next {
  width: 30px;
  height: 30px;
  background-color: transparent;
  padding: 0;
}
.evaluations-env .calendar .calendar-body .cal-view-month table tbody tr td.day {
  height: 40px;
}
.evaluations-env .calendar .calendar-body .cal-view-month table tbody tr td.day i {
  left: 3px;
  position: absolute;
  top: 5px;
}
.evaluations-env .calendar .calendar-body .cal-view-month table tbody tr td .badge {
  right: 0;
  top: 5px;
  font-size: 14px;
}
table {
  background-color: transparent;
  max-width: 100%;
}
.icon-calendar-view {
  background-image: url("../img/icon-calendar-view.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-calendar-outlook {
  background-image: url("../img/icon-calendar-outlook.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-calendar-google {
  background-image: url("../img/icon-calendar-google.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-calendar-iphone {
  background-image: url("../img/icon-calendar-iphone.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-calendar-ask-class {
  background-image: url("../img/icon-calendar-ask-class.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-calendar-change-class {
  background-image: url("../img/icon-calendar-change-class.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 29px;
  height: 28px;
  display: block;
}
.icon-calendar-collapse {
  background-image: url("../img/icon-calendar-collapse.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-calendar-add {
  background-image: url("../img/icon-calendar-add.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-individual-lesson {
  background-image: url("../img/icon-individual-lesson.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-group-lesson {
  background-image: url("../img/icon-group-lesson.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-workshop {
  background-image: url("../img/icon-workshop.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-telephone-lesson {
  background-image: url("../img/icon-telephone-lesson.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-virtual-class {
  background-image: url("../img/icon-virtual-class.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 28px;
  display: block;
}
.icon-check-correct {
  background-image: url("../img/icon-check-correct.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 24px;
  display: block;
}
.icon-check-grey {
  background-image: url("../img/icon-check-grey.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 24px;
  display: block;
}
.icon-wrong {
  background-image: url("../img/icon-wrong.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 25px;
  display: block;
}
.icon-today-class {
  background-image: url("../img/bg-task-correct.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 24px;
  display: block;
}
.icon-plus {
  background-image: url("../img/icon-plus-green.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 21px;
  height: 21px;
  display: block;
}
.icon-badge {
  background-color: #bfbfbf;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: block;
}
.icon-badge.holidays {
  background-color: #ebb700;
}
.icon-badge.next,
.icon-badge.current {
  background-color: #ff6e4c;
}
.icon-badge.green,
.icon-badge.last {
  background-color: #88b916;
}
.icon-badge.last,
.icon-badge.current {
  border-radius: 0px;
}
.icon-badge.success {
  background-color: #88b916;
}
.btn-prev i {
  font-size: 40px;
  color: #8f8f8f;
}
.btn-prev:hover i {
  color: #88b916;
}
.btn-next i {
  font-size: 40px;
  color: #8f8f8f;
}
.btn-next:hover i {
  color: #88b916;
}
.btn-up {
  background-image: url("../img/arrow-up.png");
  background-repeat: no-repeat;
  background-size: 100%;
  border-color: none;
  color: #fff;
}
.btn-down {
  background-image: url("../img/arrow-down.png");
  background-repeat: no-repeat;
  background-size: 100%;
  border-color: none;
  color: #fff;
}
.btn.btn-prev {
  width: 46px;
  height: 40px;
  background-color: transparent;
  padding: 0;
}
.btn.btn-next {
  width: 46px;
  height: 40px;
  background-color: transparent;
  padding: 0;
}
.btn.btn-up {
  width: 21px;
  height: 12px;
  background-color: transparent;
  padding: 0;
  margin: 0 10px;
}
.btn.btn-down {
  width: 21px;
  height: 12px;
  background-color: transparent;
  padding: 0;
  margin: 0 10px;
}
@media (max-width: 768px) {
  .page-header {
    margin-top: 0;
  }
  .page-header .nav-page .calendar-options {
    margin-top: 0;
  }
  .page-header .nav-page .calendar-options li a {
    padding: 3px 6px;
  }
  .page-header .nav-page .calendar-options i {
    width: 20px;
    height: 23px;
  }
  .calendar .calendar-header {
    background-color: transparent;
    color: #88b916;
    font-size: 20px;
  }
  .calendar .calendar-header h3 {
    min-width: 15ch;
    overflow: hidden;
    white-space: nowrap;
  }
  .calendar .calendar-header .btn.btn-prev,
  .calendar .calendar-header .btn.btn-next {
    width: 30px;
    height: 50px;
    margin: -33px 0 0;
  }
  .calendar .calendar-body .cal-view-month table tbody tr td.day,
  .calendar .calendar-body .cal-view-week table tbody tr td.day {
    height: 70px;
  }
  .calendar .calendar-body .cal-view-month table tbody tr td.day .cal-class,
  .calendar .calendar-body .cal-view-week table tbody tr td.day .cal-class {
    display: none;
  }
  .calendar .calendar-body .cal-view-month table tbody tr td.day.today .label-class,
  .calendar .calendar-body .cal-view-week table tbody tr td.day.today .label-class,
  .calendar .calendar-body .cal-view-month table tbody tr td.day.next .label-class,
  .calendar .calendar-body .cal-view-week table tbody tr td.day.next .label-class {
    font-size: 14px;
    width: 100%;
    bottom: 0;
    padding: 3px;
    top: auto;
  }
  .calendar .calendar-body .cal-view-month table tbody tr td .badge,
  .calendar .calendar-body .cal-view-week table tbody tr td .badge {
    right: 30%;
    top: 10%;
    background-color: transparent !important;
    box-shadow: 0px 0px 0px 0px;
    webkit-box-shadow: 0px 0px 0px 0px;
  }
  .calendar .calendar-body .cal-view-month table tbody tr td .badge.has_class,
  .calendar .calendar-body .cal-view-week table tbody tr td .badge.has_class {
    background-color: #ccc !important;
    color: #56565b;
    -webkit-box-shadow: 1px 2px 2px 0px rgba(169, 171, 172, 0.44);
    -moz-box-shadow: 1px 2px 2px 0px rgba(169, 171, 172, 0.44);
    box-shadow: 1px 2px 2px 0px rgba(169, 171, 172, 0.44);
  }
  .calendar .calendar-body .cal-view-month .classes-list .class-appointment .class,
  .calendar .calendar-body .cal-view-week .classes-list .class-appointment .class {
    display: block;
  }
  .calendar .calendar-body .cal-view-month .classes-list .class-appointment .content-image,
  .calendar .calendar-body .cal-view-week .classes-list .class-appointment .content-image {
    width: 100%;
  }
  .calendar .calendar-body .cal-view-month .classes-list .class-appointment .content-title,
  .calendar .calendar-body .cal-view-week .classes-list .class-appointment .content-title {
    width: 100%;
  }
  .calendar .calendar-body .cal-view-month .classes-list .class-appointment .class-hour,
  .calendar .calendar-body .cal-view-week .classes-list .class-appointment .class-hour {
    width: 30%;
  }
}
.classes-list .item-hour .container-image {
  width: 20%;
  float: left;
}
.classes-list .item-hour .container-info {
  padding-left: 7px;
  padding-top: 5px;
  width: 80%;
  float: left;
}
.classes-list .item-description .container-image {
  width: 20%;
  float: left;
}
.classes-list .item-description .container-info {
  width: 80%;
  float: left;
}
.list-action {
  overflow: hidden;
  display: inline-block;
  margin-left: 5%;
}
.list-action img {
  margin-top: 2%;
}
.list-action a {
  cursor: pointer;
}
@media (max-width: 768px) {
  .list-action img {
    width: 100%;
  }
  .classes-list .item-hour .container-info {
    padding-left: 20px !important;
  }
  .classes-list img.img-circle {
    width: 35px;
  }
  .classes-list img.img-circle {
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 910px) {
  .list-action img {
    width: 100%;
  }
  .classes-list img.img-circle {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 910px) {
  .item-description .container-image {
    width: 30%;
    float: left;
  }
  .item-description .container-image img {
    width: 35px;
    height: 35px;
  }
  .item-description .container-info {
    width: 70%;
    float: left;
  }
  .item-description .container-info p {
    font-size: 10px;
  }
}
@media (min-width: 1200px) {
  .list-action img {
    width: 100%;
  }
  .classes-list img.img-circle {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 490px) {
  .list-action img {
    width: 100%;
  }
  .classes-list img.img-circle {
    width: 25px;
    height: 25px;
  }
}
table.not-border {
  border-left: 0px solid #eee !important;
  border-right: 0px solid #eee !important;
  border-bottom: 0px solid #eee !important;
}
table.not-border tr {
  border-left: 0px solid #eee !important;
  border-right: 0px solid #eee !important;
  border-bottom: 0px solid #eee !important;
}
table.not-border td {
  border-left: 0px solid #eee !important;
  border-right: 0px solid #eee !important;
  border-bottom: 0px solid #eee !important;
}
.calendar.request-reschedule .cal-class {
  background-color: red;
}
.calendar-box-selecter td:not(.label-hour):hover {
  -webkit-box-shadow: 0 0 10px #88b916;
  box-shadow: 0 0 10px #88b916;
}
.calendar-box-selecter td:not(.label-hour) {
  cursor: pointer;
}
@media (max-width: 870px) {
  .calendar-day-hour.calendar-responsive {
    overflow-x: scroll;
  }
}
.calendar-day-hour.calendar-responsive table {
  min-width: 900px;
}
.calendar-day-hour.calendar-responsive table tbody .hour {
  height: 68px !important;
}
@media (max-width: 870px) {
  .calendar-day-half-hour.calendar-responsive {
    overflow-x: scroll;
  }
}
.calendar-day-half-hour.calendar-responsive table {
  min-width: 900px;
}
.calendar-day-half-hour.calendar-responsive table tbody .hour {
  height: 51px !important;
}
.lesson-itinerary {
  padding-left: 10px;
}
.lesson-itinerary .list-action {
  padding: 5px;
  margin: 0;
}
.move-activity {
  cursor: pointer;
}
.move-activity:hover {
  color: #88b916;
}
.calendar.calendar-half-hours {
  min-width: 1000px;
}
.calendar.calendar-half-hours tr td {
  width: 13% !important;
  padding-top: 5px !important;
  height: 50px !important;
  max-height: 75px !important;
}
.calendar.calendar-half-hours tr td.label-hour {
  width: 9% !important;
}
table.calendar-available {
  width: 99% !important;
  margin-left: 0.5% !important;
}
table.calendar-available th {
  text-align: center;
  vertical-align: top !important;
  padding-bottom: 8px !important;
}
table.calendar-available td.selectable {
  cursor: pointer;
  border: 1px solid #e8e8e8;
}
table.calendar-available td.available {
  background-color: #78923d;
}
table.calendar-available td.live-available {
  background-color: #a4c063 !important;
}
table.calendar-available td.negotiable {
  background-color: #e4a021;
}
table.calendar-available td.live-negotiable {
  background-color: #fdb93b !important;
}
table.calendar-available td.not-available {
  background-color: #e33838;
}
table.calendar-available td.live-not-available {
  background-color: #cc2f1fc4 !important;
}
table.calendar-available td {
  height: 25px !important;
}
table.calendar-available td.display-hour {
  padding: 4px !important;
}
table.calendar-week th:first-child {
  width: 8%;
  text-align: center;
}
table.calendar-week th:not(:first-child) {
  vertical-align: top;
  width: 18.4%;
  padding: 3px;
}
table.calendar-week.calendar-week-5 th:not(:first-child) {
  width: 18.4% !important;
}
table.calendar-week.calendar-week-6 th:not(:first-child) {
  width: 15.33333333% !important;
}
table.calendar-week.calendar-week-7 th:not(:first-child) {
  width: 13.14285714% !important;
}
table.calendar-week td.hour {
  position: relative;
}
table.calendar-week td.hour label.calendar-item-input {
  position: absolute;
  cursor: pointer;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
table.calendar-week td.hour input.calendar-item-input {
  display: none;
}
table.calendar-week td.hour input.calendar-item-input:checked + label {
  outline: 2px solid #ff6e4c;
}
/* LOGIN
============================= */
.login h1 {
  text-align: center;
  padding: 50px 0 0;
  margin: auto;
  width: 320px;
}
.login h1 a {
  background-image: none, url("../img/logo-interlang.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 250px auto;
  display: block;
  height: 65px;
  line-height: 1.3em;
  margin: 0 auto 25px;
  outline: 0 none;
  padding: 0;
  text-indent: -9999px;
}
.login a {
  color: #88b916;
  font-size: 14px;
}
.login .login-box {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  padding: 20px 40px;
}
.login-page,
.register-page {
  background: url("../img/bg-login.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-box,
.register-box {
  margin: 7% auto;
  max-width: 360px;
}
.login-box h2,
.register-box h2 {
  color: #88b916;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
}
.login-box .form-control,
.register-box .form-control {
  background-color: #f7f7f7;
}
.login-box label,
.register-box label {
  font-weight: 500;
  font-size: 14px;
}
.login-box input[type="checkbox"],
.register-box input[type="checkbox"] {
  background-color: #f7f7f7;
}
.tree.col-sm-6 {
  padding: 0;
}
/* SKILLS CIRCLE PROGRESS BAR
=============================*/
.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}
.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.14em solid #88b916;
  width: 0.72em;
  height: 0.72em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: #bfbfbf;
}
.c100 *,
.c100 *:before,
.c100 *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.c100.center {
  float: none;
  margin: 0 auto;
}
.c100.col-centered {
  float: none;
}
.c100.big {
  font-size: 240px;
}
.c100.small {
  font-size: 80px;
}
.c100 > span {
  position: absolute;
  z-index: 1;
  line-height: 1.1em;
  color: #56565b;
  display: block;
  text-align: center;
  white-space: normal;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.c100 > span.skill-title {
  width: 68%;
  left: 15%;
  top: 35%;
  font-size: 0.07em;
}
.c100 > span.skill-score {
  width: 100%;
  left: 0;
  top: 55%;
  font-size: 0.2em;
}
.c100:after {
  position: absolute;
  top: 0.14em;
  left: 0.14em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #ffffff;
  width: 0.72em;
  height: 0.72em;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
  left: 0;
}
.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -o-transform: rotate(18deg);
  transform: rotate(18deg);
}
.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -o-transform: rotate(36deg);
  transform: rotate(36deg);
}
.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.c100.p13 .bar {
  -webkit-transform: rotate(46.800000000000004deg);
  -moz-transform: rotate(46.800000000000004deg);
  -ms-transform: rotate(46.800000000000004deg);
  -o-transform: rotate(46.800000000000004deg);
  transform: rotate(46.800000000000004deg);
}
.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  transform: rotate(54deg);
}
.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -o-transform: rotate(72deg);
  transform: rotate(72deg);
}
.c100.p21 .bar {
  -webkit-transform: rotate(75.60000000000001deg);
  -moz-transform: rotate(75.60000000000001deg);
  -ms-transform: rotate(75.60000000000001deg);
  -o-transform: rotate(75.60000000000001deg);
  transform: rotate(75.60000000000001deg);
}
.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c100.p26 .bar {
  -webkit-transform: rotate(93.60000000000001deg);
  -moz-transform: rotate(93.60000000000001deg);
  -ms-transform: rotate(93.60000000000001deg);
  -o-transform: rotate(93.60000000000001deg);
  transform: rotate(93.60000000000001deg);
}
.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -o-transform: rotate(108deg);
  transform: rotate(108deg);
}
.c100.p31 .bar {
  -webkit-transform: rotate(111.60000000000001deg);
  -moz-transform: rotate(111.60000000000001deg);
  -ms-transform: rotate(111.60000000000001deg);
  -o-transform: rotate(111.60000000000001deg);
  transform: rotate(111.60000000000001deg);
}
.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -o-transform: rotate(126deg);
  transform: rotate(126deg);
}
.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.c100.p37 .bar {
  -webkit-transform: rotate(133.20000000000002deg);
  -moz-transform: rotate(133.20000000000002deg);
  -ms-transform: rotate(133.20000000000002deg);
  -o-transform: rotate(133.20000000000002deg);
  transform: rotate(133.20000000000002deg);
}
.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -o-transform: rotate(144deg);
  transform: rotate(144deg);
}
.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.c100.p42 .bar {
  -webkit-transform: rotate(151.20000000000002deg);
  -moz-transform: rotate(151.20000000000002deg);
  -ms-transform: rotate(151.20000000000002deg);
  -o-transform: rotate(151.20000000000002deg);
  transform: rotate(151.20000000000002deg);
}
.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -o-transform: rotate(162deg);
  transform: rotate(162deg);
}
.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.c100.p47 .bar {
  -webkit-transform: rotate(169.20000000000002deg);
  -moz-transform: rotate(169.20000000000002deg);
  -ms-transform: rotate(169.20000000000002deg);
  -o-transform: rotate(169.20000000000002deg);
  transform: rotate(169.20000000000002deg);
}
.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.c100.p52 .bar {
  -webkit-transform: rotate(187.20000000000002deg);
  -moz-transform: rotate(187.20000000000002deg);
  -ms-transform: rotate(187.20000000000002deg);
  -o-transform: rotate(187.20000000000002deg);
  transform: rotate(187.20000000000002deg);
}
.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -o-transform: rotate(198deg);
  transform: rotate(198deg);
}
.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.c100.p57 .bar {
  -webkit-transform: rotate(205.20000000000002deg);
  -moz-transform: rotate(205.20000000000002deg);
  -ms-transform: rotate(205.20000000000002deg);
  -o-transform: rotate(205.20000000000002deg);
  transform: rotate(205.20000000000002deg);
}
.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -o-transform: rotate(216deg);
  transform: rotate(216deg);
}
.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.c100.p62 .bar {
  -webkit-transform: rotate(223.20000000000002deg);
  -moz-transform: rotate(223.20000000000002deg);
  -ms-transform: rotate(223.20000000000002deg);
  -o-transform: rotate(223.20000000000002deg);
  transform: rotate(223.20000000000002deg);
}
.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -o-transform: rotate(234deg);
  transform: rotate(234deg);
}
.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.c100.p67 .bar {
  -webkit-transform: rotate(241.20000000000002deg);
  -moz-transform: rotate(241.20000000000002deg);
  -ms-transform: rotate(241.20000000000002deg);
  -o-transform: rotate(241.20000000000002deg);
  transform: rotate(241.20000000000002deg);
}
.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -o-transform: rotate(252deg);
  transform: rotate(252deg);
}
.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.c100.p74 .bar {
  -webkit-transform: rotate(266.40000000000003deg);
  -moz-transform: rotate(266.40000000000003deg);
  -ms-transform: rotate(266.40000000000003deg);
  -o-transform: rotate(266.40000000000003deg);
  transform: rotate(266.40000000000003deg);
}
.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.c100.p79 .bar {
  -webkit-transform: rotate(284.40000000000003deg);
  -moz-transform: rotate(284.40000000000003deg);
  -ms-transform: rotate(284.40000000000003deg);
  -o-transform: rotate(284.40000000000003deg);
  transform: rotate(284.40000000000003deg);
}
.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -o-transform: rotate(288deg);
  transform: rotate(288deg);
}
.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.c100.p84 .bar {
  -webkit-transform: rotate(302.40000000000003deg);
  -moz-transform: rotate(302.40000000000003deg);
  -ms-transform: rotate(302.40000000000003deg);
  -o-transform: rotate(302.40000000000003deg);
  transform: rotate(302.40000000000003deg);
}
.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -o-transform: rotate(306deg);
  transform: rotate(306deg);
}
.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.c100.p89 .bar {
  -webkit-transform: rotate(320.40000000000003deg);
  -moz-transform: rotate(320.40000000000003deg);
  -ms-transform: rotate(320.40000000000003deg);
  -o-transform: rotate(320.40000000000003deg);
  transform: rotate(320.40000000000003deg);
}
.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -o-transform: rotate(324deg);
  transform: rotate(324deg);
}
.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.c100.p94 .bar {
  -webkit-transform: rotate(338.40000000000003deg);
  -moz-transform: rotate(338.40000000000003deg);
  -ms-transform: rotate(338.40000000000003deg);
  -o-transform: rotate(338.40000000000003deg);
  transform: rotate(338.40000000000003deg);
}
.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -o-transform: rotate(342deg);
  transform: rotate(342deg);
}
.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.c100.p99 .bar {
  -webkit-transform: rotate(356.40000000000003deg);
  -moz-transform: rotate(356.40000000000003deg);
  -ms-transform: rotate(356.40000000000003deg);
  -o-transform: rotate(356.40000000000003deg);
  transform: rotate(356.40000000000003deg);
}
.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.c100:hover {
  cursor: default;
}
.c100:hover > span {
  color: #88b916;
}
.c100:hover > span.skill-title {
  font-size: 0.1em;
}
.c100:hover > span.skill-score {
  font-size: 0.3em;
}
.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}
.c100.dark {
  background-color: #777777;
}
.c100.dark .bar,
.c100.dark .fill {
  border-color: #7b9440 !important;
}
.c100.dark > span {
  color: #777777;
}
.c100.dark:after {
  background-color: #666666;
}
.c100.dark:hover > span {
  color: #7b9440;
}
.c100.green .bar,
.c100.green .fill {
  border-color: #88b916 !important;
}
.c100.green:hover > span {
  color: #88b916;
}
.c100.green.dark .bar,
.c100.green.dark .fill {
  border-color: #5fd400 !important;
}
.c100.green.dark:hover > span {
  color: #5fd400;
}
.c100.orange .bar,
.c100.orange .fill {
  border-color: #ebb700 !important;
}
.c100.orange:hover > span {
  color: #ebb700;
}
.c100.orange.dark .bar,
.c100.orange.dark .fill {
  border-color: #e08833 !important;
}
.c100.orange.dark:hover > span {
  color: #e08833;
}
@media (max-width: 767px) {
  .c100.big {
    font-size: 160px;
  }
}
.survey .survey-section .rating-head,
.survey .survey-section .rating-question,
.survey .survey-section .rating-value {
  display: inline-block;
}
.survey .survey-section .rating-question {
  vertical-align: middle !important;
  padding-left: 8px !important;
}
.survey .survey-section .row-head {
  padding-bottom: 10px;
}
.survey .survey-section .row-value:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.survey .survey-section .row-value {
  border-top: 1px solid #ddd;
}
.survey .survey-section label.label_radio {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0px !important;
}
.survey .radio input {
  width: 42px !important;
}
.survey.survey-fundae .page {
  height: 100%;
}
.survey.survey-fundae .page h1 {
  font-size: 26px;
}
.survey.survey-fundae .page h3 {
  font-size: 24px;
}
.survey.survey-fundae .page .page-body .section {
  border: 3px solid gray;
  margin-bottom: 15px;
  overflow: hidden;
}
.survey.survey-fundae .page .page-body .section h3 {
  margin: 0;
  padding: 8px 8px;
  margin-bottom: 10px;
  border-bottom: 3px solid gray;
}
.survey.survey-fundae .page .page-body .section .table tbody tr:first-of-type {
  border-top: 0;
}
.survey.survey-fundae .page .page-body .section .table tbody tr:first-of-type td {
  border-top: 0;
}
.survey.survey-fundae .page .page-body .section .table tbody tr td.number {
  background-color: #c0c0c0;
  color: black;
  text-align: center;
  border: 1px solid gray;
}
.survey.survey-fundae .page .page-body .section .table tbody tr td.number:last-of-type {
  border-right: 0;
}
.survey.survey-fundae .page .page-body .section .table tbody tr td.check {
  border: 1px solid gray;
  text-align: center;
}
.survey.survey-fundae .page .page-body .section .table tbody tr td.check:last-of-type {
  border-right: 0;
}
.survey.survey-fundae .page.page-one .page-body .section .table-header h1 {
  font-size: 26px;
  text-align: center;
}
.survey.survey-fundae .page.page-one .page-body .section .table tbody tr td.check {
  border-right: 1px solid gray;
}
.survey[class$="_dev"] .page,
.survey[class$="_dev"] .layout {
  border-top: 1px solid deeppink;
  border-bottom: 1px solid deeppink;
}
.survey .layout,
.survey .page {
  height: 1029px;
  width: 1530px;
  page-break-before: always !important;
  position: relative;
  margin-left: 15px;
  padding: 60px 80px;
}
.survey .layout .page-header,
.survey .page .page-header {
  margin: 20px 0 60px;
  border-bottom: 0;
}
.survey .layout .page-header .logo,
.survey .page .page-header .logo {
  display: inline-block;
}
.survey .layout .page-header .logo-fundae,
.survey .page .page-header .logo-fundae {
  display: inline-block;
  float: right;
  margin-top: -5px;
}
.survey .layout .page-header .logo-fundae img,
.survey .page .page-header .logo-fundae img {
  width: 503px;
  height: 80px;
}
.survey .layout h3.page-title,
.survey .page h3.page-title {
  font-size: 36px !important;
  color: #88b916;
  font-weight: 600;
  margin-bottom: 40px;
}
.survey .layout h3.page-title strong,
.survey .page h3.page-title strong {
  font-size: 36px !important;
}
.survey .layout .table th,
.survey .page .table th,
.survey .layout .table td,
.survey .page .table td {
  font-size: 17px;
}
.survey .layout .rating-head,
.survey .page .rating-head {
  font-size: 17px;
}
.survey .layout .rating-question,
.survey .page .rating-question {
  font-size: 17px;
}
.survey .layout .survey-section h3,
.survey .page .survey-section h3 {
  font-size: 24px;
}
.survey .layout *,
.survey .page * {
  font-size: 17px;
}
.survey .layout input[type='radio']:after,
.survey .page input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: absolute;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.survey .layout input[type='radio']:checked:after,
.survey .page input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: absolute;
  background-color: #88b916;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.survey .layout .survey-section,
.survey .page .survey-section {
  margin-top: 70px;
}
.survey .layout .page-header,
.survey .page .page-header {
  height: 80px;
}
.survey .layout .page-header img,
.survey .page .page-header img {
  height: 80px;
}
.survey .layout .page-header h4,
.survey .page .page-header h4 {
  margin-top: 30px;
}
.survey .box-body {
  padding-top: 0;
}
.survey .table tr th,
.survey .table tr td {
  vertical-align: middle !important;
}
.survey .table-bordered {
  border: 1px solid #757575;
}
.survey .table-bordered > thead > tr > th,
.survey .table-bordered > tbody > tr > th,
.survey .table-bordered > tfoot > tr > th,
.survey .table-bordered > thead > tr > td,
.survey .table-bordered > tbody > tr > td,
.survey .table-bordered > tfoot > tr > td {
  border: 1px solid #757575;
}
.survey .list-inline li {
  display: inline-block;
}
@media (max-width: 768px) {
  .rating-question {
    width: 100% !important;
    margin-bottom: 10px;
    display: list-item !important;
    list-style-type: disc;
    list-style-position: inside;
  }
  .rating-question span {
    font-size: 1.3em;
  }
  .rating-value {
    display: block !important;
    width: 100% !important;
  }
  .rating-value .label_radio {
    text-align: inherit !important;
  }
  .rating-value .label_radio span {
    font-weight: 400;
    font-size: 1.15em;
    color: black;
  }
  .rating-value .radio {
    padding-left: 30px;
    width: 65px;
    display: inline-block;
  }
  .rating-value .radio div:first-of-type {
    margin-right: 12px;
  }
  .row-value {
    background-color: unset !important;
    border: unset !important;
    margin-bottom: 20px;
  }
  .stars i {
    font-size: 45px !important;
    margin-left: 25px !important;
  }
  .stars i:first-of-type {
    margin-left: 0px !important;
  }
  .stars {
    margin-bottom: 30px;
    margin-top: 30px;
    padding-top: 15px;
    height: 60px;
    background-color: #f9f9f9;
  }
}
.box {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}
.box .box-header {
  padding-left: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d2d2d2;
}
.box .box-header h3 {
  font-size: 20px;
}
.box .question .question-title p {
  color: #88b916;
  font-weight: 600;
  font-size: 15px;
}
.box .question .question-body {
  padding-left: 30px;
  margin-bottom: 20px;
}
.box .question .question-body .rating-value {
  display: inline-block;
  min-width: 100px;
}
label.label_radio:hover {
  background-color: #e4e4e4 !important;
}
label.label_radio {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0px !important;
}
.radio label,
.checkbox label {
  padding-left: 0px !important;
}
@media (max-width: 768px) {
  .box .box-header h3 {
    font-size: 20px;
  }
  .question .question-body {
    padding-left: 15px;
    margin-bottom: 20px;
  }
  .question .question-body .rating-value {
    display: inline-block !important;
    min-width: 50px !important;
    max-width: 60px !important;
  }
}
.logo {
  max-width: 280px;
  max-height: 55px;
  background-image: url("../img/logo-interlang.png");
  width: 100% !important;
  display: block;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
  margin: 0;
  background-position-x: 0;
}
.icheckbox_square-green,
.iradio_square-green {
  background-image: url("../img/icheck-success.png") !important;
}
